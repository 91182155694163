import {
  applySnapshot,
  type Instance,
  type IStateTreeNode,
  type IType,
  onSnapshot,
  types,
} from "mobx-state-tree";
import localForage from "localforage";

import { WatchHistoryModel } from "@/entities/watch-history/model/watch-history-model";
import { PlayerModel } from "@/entities/player/model/player-model";
import { AddToHomeModel } from "@/entities/add-to-home/model/add-to-home-model";

export const RootModel = types.model("RootModel", {
  watchHistory: WatchHistoryModel,
  player: PlayerModel,
  addToHome: AddToHomeModel,
});

export type Store = Instance<typeof RootModel>;

export const store = RootModel.create({
  watchHistory: {
    records: [],
  },
  player: {
    selectedSource: "alloha",
  },
  addToHome: {
    hidden: false,
  },
});

function persist(
  target: IStateTreeNode<IType<any, unknown, any>>,
  key: string,
) {
  if (typeof window === "undefined") {
    return;
  }

  onSnapshot(target, async (snapshot) => {
    await localForage.setItem(key, snapshot);
  });
}

function rehydrate(
  target: IStateTreeNode<IType<any, unknown, any>>,
  key: string,
): Promise<void> {
  if (typeof window === "undefined") {
    return Promise.resolve();
  }

  return localForage.getItem(key).then((value) => {
    if (value === null) {
      return;
    }

    applySnapshot(target, value);
  });
}

persist(store.watchHistory, "watchHistory");
persist(store.player, "player");
persist(store.addToHome, "addToHome");

if (typeof window !== "undefined") {
  Promise.allSettled([
    rehydrate(store.watchHistory, "watchHistory"),
    rehydrate(store.player, "player"),
    rehydrate(store.addToHome, "addToHome"),
  ]);
}

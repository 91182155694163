type HttpErrorOptions = ErrorOptions & {
  readonly status: number;
};

export class HttpError<T> extends Error {
  _data: T;
  _status: number;

  constructor(data: T, options: HttpErrorOptions) {
    super(undefined, { cause: options.cause });

    this._data = data;
    this._status = options.status;
  }

  get data(): T {
    return this._data;
  }

  get status() {
    return this._status;
  }
}

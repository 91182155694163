"use client";

import { type SyntheticEvent, useState, Suspense } from "react";
import { usePathname } from "next/navigation";
import { useIntl } from "react-intl";
import { Box, useTheme } from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  Home as HomeIcon,
  Search as SearchIcon,
} from "@mui/icons-material";

import { Partytown } from "@builder.io/partytown/react";
import { Providers } from "@/app/providers/providers";
import {
  NavigationRail,
  NavigationRailAction,
} from "@/features/navigation-rail/ui";
import { SnackStack } from "@/features/snack-stack";
import { YandexMetrikaCounter } from "@/features/analytics/yandex-metrika-counter";

export default function BaseLayout({ children }: React.PropsWithChildren) {
  const { formatMessage } = useIntl();
  const pathname = usePathname()!;
  const theme = useTheme();

  const [navValue, setNavValue] = useState(pathname);

  const handleNavValueChange = (_: SyntheticEvent, newValue: string) => {
    setNavValue(newValue);
  };

  const isRootPath = ["/", "/search", "/profile"].includes(pathname);

  return (
    <>
      <Partytown
        forward={["ym"]}
        debug={process.env.NODE_ENV === "development"}
      />
      <YandexMetrikaCounter id={97178439} partytown={true} />

      <Suspense>
        <Providers>
          <SnackStack />
          <Box
            sx={{
              minHeight: "calc(100vh)",
              padding:
                "0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
            }}
          >
            <Box
              sx={{
                visibility: isRootPath ? "visible" : "hidden",
              }}
              component={NavigationRail}
              value={navValue}
              onChange={handleNavValueChange}
            >
              <NavigationRailAction
                label={formatMessage({ id: "main" })}
                icon={<HomeIcon />}
                value={"/"}
              />
              <NavigationRailAction
                label={formatMessage({ id: "search" })}
                icon={<SearchIcon />}
                value={"/search"}
              />
              <NavigationRailAction
                label={formatMessage({ id: "profile" })}
                icon={<AccountCircleIcon />}
                value={"/profile"}
              />
            </Box>
            <Box
              sx={{
                pb: isRootPath ? "72px" : "unset",
                [theme.breakpoints.up("md")]: {
                  pl: isRootPath ? "80px" : "unset",
                },
              }}
            >
              {children}
            </Box>
          </Box>
        </Providers>
      </Suspense>
    </>
  );
}

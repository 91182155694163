"use client";

import React from "react";
import { IntlProvider, IntlConfig } from "react-intl";

interface TranslationProviderProps extends React.PropsWithChildren {
  readonly messages: IntlConfig["messages"];
}

export function TranslationProvider({
  messages,
  children,
}: TranslationProviderProps): JSX.Element {
  return (
    <IntlProvider locale={`ru`} messages={messages}>
      {children}
    </IntlProvider>
  );
}

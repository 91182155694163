import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HttpError } from "../http-error";
import { wikidataClient } from "../wikidata/client";
import { vibixClient } from "./client";
import type { VibixDataResponse } from "./types";

export const vibixApi = createApi({
  reducerPath: "vibixApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getData: builder.query<VibixDataResponse, string>({
      queryFn: async (imdbId: string) => {
        try {
          const kinopoiskId =
            await wikidataClient.getKinopoiskIdByImdbId(imdbId);

          return { data: await vibixClient.getData(kinopoiskId) };
        } catch (err) {
          if (err instanceof HttpError) {
            return {
              error: {
                status: err._status,
                data: err._data,
              },
            };
          }

          throw new Error("Неизвестная ошибка", { cause: err });
        }
      },
    }),
  }),
});

export const { useGetDataQuery } = vibixApi;

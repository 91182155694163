"use client";

import { memo } from "react";
import Link from "next/link";
import BottomNavigationAction, {
  type BottomNavigationActionProps,
} from "@mui/material/BottomNavigationAction";
import { type SxProps, type Theme, useTheme } from "@mui/material/styles";

interface NavigationRailActionProps extends BottomNavigationActionProps {}

export const NavigationRailAction: React.FC<NavigationRailActionProps> = memo(
  ({ value, ...props }) => {
    const theme = useTheme();

    const sx: SxProps<Theme> = {
      [theme.breakpoints.up("md")]: {
        maxWidth: 72,
        minWidth: 72,
        minHeight: 72,
        maxHeight: 72,
      },
      padding: "14px 8px",
      "&.MuiBottomNavigationAction-iconOnly": {
        paddingTop: "30px",
        paddingBottom: "10px",
      },
      "&.Mui-selected": {
        paddingTop: "0px",
      },
      label: {
        paddingTop: "4px",
        "&.Mui-selected": {
          fontSize: (theme) => theme.typography.pxToRem(14),
        },
      },
    };

    return (
      <BottomNavigationAction
        component={Link}
        href={value}
        value={value}
        replace={true}
        {...props}
        sx={sx}
      />
    );
  },
);

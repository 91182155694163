import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HttpError } from "../http-error";
import { videoCdnClient } from "./client";
import type { VideoCdnShortResult } from "./types";

export const videoCdnApi = createApi({
  reducerPath: "videoCdnApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getShort: builder.query<VideoCdnShortResult, string>({
      queryFn: async (imdbId: string) => {
        try {
          return { data: await videoCdnClient.getShort(imdbId) };
        } catch (err) {
          if (err instanceof HttpError) {
            return {
              error: {
                status: err._status,
                data: err._data,
              },
            };
          }

          throw new Error("Неизвестная ошибка", { cause: err });
        }
      },
    }),
  }),
});

export const { useGetShortQuery } = videoCdnApi;

import { type Instance, types } from "mobx-state-tree";

export const AddToHomeModel = types
  .model({
    hidden: types.boolean,
  })
  .actions((self) => ({
    hide() {
      self.hidden = true;
    },
  }));

export type AddToHomeModelType = Readonly<Instance<typeof AddToHomeModel>>;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HttpError } from "../http-error";
import { cdnMoviesClient } from "./client";
import type { CdnMoviesContents } from "./types";

export const cdnMoviesApi = createApi({
  reducerPath: "cdnMoviesApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    getContents: builder.query<CdnMoviesContents, string>({
      queryFn: async (imdbId: string) => {
        try {
          return { data: await cdnMoviesClient.getContents(imdbId) };
        } catch (err) {
          if (err instanceof HttpError) {
            return {
              error: {
                status: err._status,
                data: err._data,
              },
            };
          }

          throw new Error("Неизвестная ошибка", { cause: err });
        }
      },
    }),
  }),
});

export const { useGetContentsQuery } = cdnMoviesApi;

"use client";

import { createContext } from "react";
import { store, Store } from "./mobx-store";

export const StoreContext = createContext<Store>(store);

export function StoreProvider({ children }: React.PropsWithChildren) {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HttpError } from "../http-error";
import { wikidataClient } from "../wikidata/client";
import { kodikClient } from "./client";
import type { KodikSearch } from "./types";

export const kodikApi = createApi({
  reducerPath: "kodikApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    search: builder.query<KodikSearch, string>({
      queryFn: async (imdbId: string) => {
        try {
          const kinopoiskId =
            await wikidataClient.getKinopoiskIdByImdbId(imdbId);

          return { data: await kodikClient.search(kinopoiskId) };
        } catch (err) {
          if (err instanceof HttpError) {
            return {
              error: {
                status: err._status,
                data: err._data,
              },
            };
          }

          throw new Error("Неизвестная ошибка", { cause: err });
        }
      },
    }),
  }),
});

export const { useSearchQuery } = kodikApi;

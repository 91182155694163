import { HttpError } from "../http-error";
import type { CdnMoviesContents } from "./types";

class CdnMoviesClient {
  _baseUrl!: string;
  _token!: string;

  constructor(baseUrl: string, token: string) {
    this._baseUrl = baseUrl;
    this._token = token;
  }

  getContents(imdbId: string) {
    return this.request<CdnMoviesContents>(
      "/v1/contents",
      new URLSearchParams([["imdb_id", imdbId]]),
    ).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);
    url.searchParams.append("token", this._token);

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    const request = new Request(url);
    const result = await fetch(request, {
      // mode: "no-cors",
    });
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const cdnMoviesClient = new CdnMoviesClient(
  "https://api.cdnmovies.net",
  "e94f0b374999f9cb83f6486e91b23677",
);

import { HttpError } from "../http-error";
import type { WikidataKinopoiskId } from "./types";

export interface WikidataClient {
  getKinopoiskIdByImdbId(imdbId: string): Promise<string>;
}

class WikidataClientImpl implements WikidataClient {
  _baseUrl!: string;

  constructor(baseUrl: string) {
    this._baseUrl = baseUrl;
  }

  getKinopoiskIdByImdbId(imdbId: string) {
    return this.request<WikidataKinopoiskId>(
      "/sparql",
      new URLSearchParams([
        [
          "query",
          `SELECT ?kinopoisk_id
      WHERE
      {
        ?item wdt:P345 ?imdb_id.
        ?item wdt:P2603 ?kinopoisk_id.
        values ?imdb_id {"${imdbId}"}
      }`,
        ],
      ]),
    )
      .then(({ results }) => results.bindings[0].kinopoisk_id.value)
      .catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);
    url.searchParams.append("format", "json");

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    const request = new Request(url);
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const wikidataClient = new WikidataClientImpl(
  "https://query.wikidata.org",
);

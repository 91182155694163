import { type Instance, types } from "mobx-state-tree";
import type { Source } from "@/shared/types/common";

export const PlayerModel = types
  .model({
    selectedSource: types.enumeration<Source>([
      "alloha",
      "vibix",
      "videocdn",
      "cdnmovies",
      "collaps",
      "kodik",
    ]),
  })
  .actions((self) => ({
    selectSource(source: Source) {
      self.selectedSource = source;
    },
  }));

export type PlayerModelType = Readonly<Instance<typeof PlayerModel>>;

import { configureStore } from "@reduxjs/toolkit";

import { tmdbApi } from "../shared/api/tmdb/api";
import { videoCdnApi } from "../shared/api/videocdn/api";
import { cdnMoviesApi } from "../shared/api/cdnmovies/api";
import { collapsApi } from "../shared/api/collaps/api";
import { kodikApi } from "../shared/api/kodik/api";
import { allohaApi } from "@/shared/api/alloha/api";
import { vibixApi } from "@/shared/api/vibix/api";

export function makeStore() {
  const store = configureStore({
    reducer: {
      [tmdbApi.reducerPath]: tmdbApi.reducer,
      [allohaApi.reducerPath]: allohaApi.reducer,
      [videoCdnApi.reducerPath]: videoCdnApi.reducer,
      [cdnMoviesApi.reducerPath]: cdnMoviesApi.reducer,
      [collapsApi.reducerPath]: collapsApi.reducer,
      [kodikApi.reducerPath]: kodikApi.reducer,
      [vibixApi.reducerPath]: vibixApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(tmdbApi.middleware)
        .concat(allohaApi.middleware)
        .concat(videoCdnApi.middleware)
        .concat(cdnMoviesApi.middleware)
        .concat(collapsApi.middleware)
        .concat(vibixApi.middleware)
        .concat(kodikApi.middleware),
  });

  return store;
}

export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore["dispatch"];

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { HttpError } from "../http-error";
import { allohaClient } from "./client";
import type { AllohaResponse } from "./types";

export const allohaApi = createApi({
  reducerPath: "allohaApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    get: builder.query<AllohaResponse, string>({
      queryFn: async (imdbId: string) => {
        try {
          return { data: await allohaClient.get(imdbId) };
        } catch (err) {
          if (err instanceof HttpError) {
            return {
              error: {
                status: err.status,
                data: err.data,
              },
            };
          }

          throw new Error("Неизвестная ошибка", { cause: err });
        }
      },
    }),
  }),
});

export const { useGetQuery } = allohaApi;

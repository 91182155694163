import { HttpError } from "../http-error";
import type { CollapsList } from "./types";

class CollapsClient {
  _baseUrl!: string;
  _token!: string;

  constructor(baseUrl: string, token: string) {
    this._baseUrl = baseUrl;
    this._token = token;
  }

  getList(kinopoiskId: string) {
    return this.request<CollapsList>(
      "/list",
      new URLSearchParams([["kinopoisk_id", kinopoiskId]]),
    ).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);
    url.searchParams.append("token", this._token);

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    const request = new Request(url);
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const collapsClient = new CollapsClient(
  "https://api.bhcesh.me/",
  "0763bbaae9b9aab3bdd731c918773bd2",
);

import { HttpError } from "../http-error";
import type { VideoCdnShortResult } from "./types";

class VideoCdnClient {
  _baseUrl!: string;
  _apiToken!: string;

  constructor(baseUrl: string, apiToken: string) {
    this._baseUrl = baseUrl;
    this._apiToken = apiToken;
  }

  getShort(imdbId: string) {
    return this.request<VideoCdnShortResult>(
      "/api/short",
      new URLSearchParams([["imdb_id", imdbId]]),
    ).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);
    url.searchParams.append("api_token", this._apiToken);

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    const request = new Request(url);
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const videoCdnClient = new VideoCdnClient(
  "https://videocdn.tv",
  "tUrq5AvE6Qiy0OnSePVCbxp8kgOFlNI9",
);

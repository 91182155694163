import { HttpError } from "../http-error";
import type { VibixDataResponse } from "./types";

function encodeData<T extends Record<string, unknown>>(
  data: T,
  parentKey?: string,
): string {
  const items = [];

  for (const key in data) {
    if (Object.hasOwn(data, key)) {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;

      if (
        typeof data[key] === "object" &&
        data[key] !== null &&
        !Array.isArray(data[key])
      ) {
        // @ts-expect-error I'm lazy
        items.push(encodeData(data[key], newKey));
      } else if (Array.isArray(data[key])) {
        // @ts-expect-error I'm lazy
        data[key].forEach((item, index) => {
          items.push(encodeData(item, `${newKey}[${index}]`));
        });
      } else {
        items.push(
          // @ts-expect-error I'm lazy
          `${encodeURIComponent(newKey)}=${encodeURIComponent(data[key])}`,
        );
      }
    }
  }

  return items.join("&");
}

class VibixClient {
  _baseUrl!: string;
  _apiToken!: string;

  constructor(baseUrl: string, apiToken: string) {
    this._baseUrl = baseUrl;
    this._apiToken = apiToken;
  }

  getData(kinopoiskId: string) {
    return this.request<VibixDataResponse>("/api/v1/publisher/catalog/data", {
      draw: 1,
      search: {
        value: kinopoiskId,
      },
    }).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(
    path: string,
    data: Record<string, unknown> = {},
  ): Promise<T> {
    const url = new URL(path, this._baseUrl);

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this._apiToken}`);
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const request = new Request(url, {
      method: "POST",
      headers,
      body: encodeData(data),
    });
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const vibixClient = new VibixClient(
  "https://vibix.org",
  "904|GBIdubCXWVRvM2xNRE9Ztf46ZKb5e5dIMm8nzNBd393abdf1",
);

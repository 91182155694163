import { types, applySnapshot } from "mobx-state-tree";
import {
  type WatchHistoryRecordModelType,
  WatchHistoryRecordModel,
} from "./watch-history-record-model";

export const WatchHistoryModel = types
  .model("WatchHistoryModel", {
    records: types.array(WatchHistoryRecordModel),
  })
  .views((self) => ({
    get sortedRecords() {
      return self.records
        .filter(
          (value, index, array) =>
            array.findIndex(({ id }) => id === value.id) === index,
        )
        .slice(0, 6)
        .toSorted(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
    },
  }))
  .actions((self) => ({
    addRecord(record: WatchHistoryRecordModelType) {
      self.records.push(record);
    },

    clear() {
      applySnapshot(self, { records: [] });
    },
  }));

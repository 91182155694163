import { HttpError } from "../http-error";
import type { AllohaResponse } from "./types";

class AllohaClient {
  #baseUrl!: string;
  #token!: string;

  constructor(baseUrl: string, token: string) {
    this.#baseUrl = baseUrl;
    this.#token = token;
  }

  get(imdbId: string) {
    return this.request<AllohaResponse>(
      "/",
      new URLSearchParams([["imdb", imdbId]]),
    ).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this.#baseUrl);
    url.searchParams.append("token", this.#token);

    if (searchParams instanceof URLSearchParams) {
      for (const [key, value] of searchParams.entries()) {
        url.searchParams.append(key, value);
      }
    }

    const request = new Request(url);
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const allohaClient = new AllohaClient(
  "https://api.apbugall.org",
  "f6b05851f1c5c208da93f8c62e9bc3",
);

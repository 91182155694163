import { HttpError } from "../http-error";
import type { KodikSearch } from "./types";

class KodikClient {
  _baseUrl!: string;
  _token!: string;

  constructor(baseUrl: string, token: string) {
    this._baseUrl = baseUrl;
    this._token = token;
  }

  search(kinopoiskId: string) {
    return this.request<KodikSearch>(
      "/search",
      new URLSearchParams([["kinopoisk_id", kinopoiskId]]),
    ).catch(() => Promise.reject(new HttpError(undefined, { status: 404 })));
  }

  async request<T>(path: string, searchParams?: URLSearchParams): Promise<T> {
    const url = new URL(path, this._baseUrl);
    url.searchParams.append("token", this._token);

    if (searchParams instanceof URLSearchParams) {
      searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });
    }

    const request = new Request(url);
    const result = await fetch(request);
    if (!result.ok) {
      throw new HttpError(undefined, { status: result.status });
    }
    return result.json() as T;
  }
}

export const kodikClient = new KodikClient(
  "https://kodikapi.com",
  "f99056bda7af7cbfc2621e8d5ad9f732",
);
